import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

function App() {
  useEffect(() => {
    if (isAndroid) {
      window.location.href = "https://play.google.com/store";
      return;
    }

    if (isIOS) {
      window.location.href = "https://www.apple.com/app-store/";
      return;
    }

    window.location.href = "https://play.google.com/store";
  }, []);

  return <div />;
}

export default App;
